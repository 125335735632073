import React from 'react';
import Modal from 'react-modal';
import './ModalLayout.scss';

const ModalLayout = ({ isOpen, toggleModal, children, header, footer, maxHeight, maxWidth }) => {
    const customStyles = {
        overlay: {
            background: '#00000080',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            border: 'none',
            overflow: 'auto',
            borderRadius: '27px',
            width: '100%',
            height: '100%',
            maxWidth: maxWidth ? maxWidth : '90%',
            maxHeight: maxHeight ? maxHeight : '90%',
            display: 'flex',
            flexDirection: 'column',
            background: 'none',
        },
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={toggleModal} style={customStyles} appElement={document.getElementById('root')}>
            <div className="react-modal">
                <div className="modal-header">{header}</div>
                <div className="modal-body">{children}</div>
                {footer && <div className="modal-footer">{footer}</div>}
            </div>
        </Modal>
    );
};

export default ModalLayout;
