import React from 'react';
import { useState, useEffect } from 'react';
import { ReactComponent as LocationSVG } from 'assets/icons/Location.svg';
import BuildingSidebar from './BuildingSidebar';
import CanvasPlane from './CanvasPlane';
import BuildingCard from './BuildingCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ButtonComponent from '../Button/Button';
import HeatMapPlane from './HeatMapPlane';
import './SkeletonBuildingView.scss';
import { getUsersCompanyById } from 'services/companies';

const SkeletonBuildingView = (props) => {
    const {
        selectedLocation,
        selectedRoute,
        setSelectedRoute,
        setSelectedLocation,
        setSelectedTrap,
        locations,
        title,
        onPressAdd,
        onPressNew,
        onPressDelete,
        onPressEdit,
        heatMap,
        canvas,
        plane,
        onEditCanvas,
        onDeleteCanvas,
    } = props;

    const [companyName, setCompanyName] = useState('');

    useEffect(() => {
        const fetchCompanyName = async () => {
            if (selectedLocation?.idCompany) {
                try {
                    const { name } = await getUsersCompanyById(selectedLocation.idCompany);
                    setCompanyName(name);
                } catch (error) {
                    console.error('Error fetching company name:', error);
                }
            }
        };

        fetchCompanyName();
    }, [selectedLocation.idCompany]);

    return (
        <div className="building-wrapper">
            <div className="building-header">
                <h1>{title}</h1>
                {onPressNew && (
                    <ButtonComponent className="building-header-button" children={onPressNew.title} onClick={() => onPressNew.action()} />
                )}
            </div>
            <div className="building-content-wrapper">
                {selectedLocation ? (
                    <>
                        <BuildingSidebar
                            selectedLocation={selectedLocation}
                            selectedLocationCompany={companyName}
                            setSelectedLocation={setSelectedLocation}
                            locations={locations}
                        />
                        <div className="building-content">
                            <div className="building-content-header">
                                <div className="building-content-header-info">
                                    <h2>
                                        {selectedLocation?.name} ({companyName})
                                    </h2>
                                    <div className="building-content-header-location">
                                        <LocationSVG />
                                        <h4>{selectedLocation?.address}</h4>
                                    </div>
                                </div>
                                <div className="building-content-header-buttons">
                                    {onPressEdit && (
                                        <ButtonComponent
                                            className="building-content-header-buttons-edit"
                                            children={onPressEdit.title}
                                            onClick={() => onPressEdit.action()}
                                        />
                                    )}
                                    {onPressDelete && (
                                        <ButtonComponent
                                            className="building-content-header-buttons-delete"
                                            children={onPressDelete.title}
                                            onClick={() => onPressDelete.action()}
                                        />
                                    )}
                                    {onPressAdd && (
                                        <ButtonComponent
                                            className="building-content-header-buttons-add"
                                            children={onPressAdd.title}
                                            onClick={() => onPressAdd.action()}
                                        />
                                    )}
                                </div>
                            </div>
                            {selectedRoute ? (
                                <>
                                    {heatMap && <HeatMapPlane selectedRoute={selectedRoute} />}
                                    {canvas && (
                                        <CanvasPlane
                                            selectedRoute={selectedRoute}
                                            onEdit={onEditCanvas}
                                            onDelete={onDeleteCanvas}
                                            setSelectedTrap={setSelectedTrap}
                                        />
                                    )}
                                    {plane && (
                                        <div className="building-content-main-route">
                                            <img
                                                className="building-content-main-route-image"
                                                src={selectedRoute.img}
                                                alt="Selected route"
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="building-content-main-route">
                                    <p>No routes have been added yet.</p>
                                </div>
                            )}

                            <div className="building-content-building">
                                {selectedLocation?.routes?.length > 0
                                    ? selectedLocation?.routes?.map((route, index) => {
                                          return <BuildingCard route={route} index={index} setSelectedRoute={setSelectedRoute} />;
                                      })
                                    : null}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="building-content-no-buildings">
                        <FontAwesomeIcon icon={faInfoCircle} color="#14AF8D" />
                        <h4>No building added yet</h4>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SkeletonBuildingView;
