import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ModalLayout from 'components/elements/ModalLayout/ModalLayout';
import ButtonComponent from 'components/elements/Button/Button';
import InputComponent from 'components/elements/Input/Input';
import { ReactComponent as CheckCircleSVG } from 'assets/icons/checkcircle.svg';
import Loading from 'components/elements/Loading/Loading';
import { Alert } from '@mui/material';
import './UsersPestIDForm.scss';
import { useRouteMatch } from 'react-router-dom';
import { getCompanyUserId, getUserRole } from 'services/utils';

const UsersPestIDForm = (props) => {
    const { submit, modalVisible, setModalVisible, userSelected, companies } = props;
    const [selectorRoleOpen, setSelectorRoleOpen] = useState(false);
    const [selectorCompaniesOpen, setSelectorCompaniesOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const userRole = ['Employee', 'Admin'];
    const [numPage, setNumPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const formRef = useRef();
    const match = useRouteMatch();
    const companyId = match.params.id;
    const companyFromParam = companyId ? companies.find((comp) => comp.idCompany === companyId) : null;
    const role = getUserRole();
    const companyIdCmsManager = getCompanyUserId();
    const companyForCMS = role === 'cmsmanager' ? companies.find((comp) => comp.idCompany === companyIdCmsManager) : null;

    const getInitialValues = () => {
        if (userSelected) {
            return {
                company: { idCompany: userSelected.company?.idCompany, name: userSelected.company?.name },
                name: userSelected.name,
                mail: userSelected.user.email,
                role: userSelected.user.role === 'Professional' ? 'Employee' : 'Admin',
                idUser: userSelected.user.id,
            };
        }

        if (companyFromParam) {
            return {
                company: { idCompany: companyFromParam.idCompany, name: companyFromParam.name },
                name: '',
                mail: '',
                role: 'Select Role',
                idUser: '',
            };
        }

        if (role === 'cmsmanager') {
            return {
                company: companyForCMS ? { idCompany: companyForCMS.idCompany, name: companyForCMS.name } : { idCompany: '', name: '' },
                name: '',
                mail: '',
                role: 'Select Role',
                idUser: '',
            };
        }

        return {
            company: { idCompany: '', name: '' },
            name: '',
            mail: '',
            role: 'Select Role',
            idUser: '',
        };
    };

    const initialValues = getInitialValues();

    const validationSchema = Yup.object().shape({
        company: Yup.object().required(),
        name: Yup.string().required(),
        mail: Yup.string().email().required(),
        role: Yup.string().oneOf(['Employee', 'Admin'], 'Invalid role').required(),
    });

    const handleClose = (formik) => {
        setModalVisible(false);
        formik.resetForm();
        setNumPage(1);
        setSelectorCompaniesOpen(false);
        setSelectorRoleOpen(false);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        setNumPage(1);
    };

    const onClickRowRole = (role, formik) => {
        formik.setFieldValue('role', role);
        setSelectorRoleOpen(false);
    };

    const onClickRowCompany = (company, formik) => {
        formik.setFieldValue('company', company);
        setSelectorCompaniesOpen(false);
    };

    const onClickSelectorCompany = () => {
        setSelectorRoleOpen(false);
        setSelectorCompaniesOpen(!selectorCompaniesOpen);
    };

    const onClickSelectorRole = () => {
        setSelectorCompaniesOpen(false);
        setSelectorRoleOpen(!selectorRoleOpen);
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        setLoading(true);
                        setNumPage(2);
                        await submit(values);
                    } catch (error) {
                        setErrors({ submit: error.message });
                        const message =
                            error.error.statusCode === 409 ? 'The email address you entered is already in use.' : error.error.message;
                        setMessage(message);
                        setShowAlert(true);
                        setModalVisible(false);
                        throw error;
                    } finally {
                        setLoading(false);
                        setSubmitting(false);
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalLayout
                            maxWidth={'783px'}
                            isOpen={modalVisible}
                            toggleModal={() => handleClose(formik)}
                            footer={
                                <div className="modal-users-pestid-footer-content">
                                    {numPage === 1 ? (
                                        <>
                                            <ButtonComponent
                                                className="modal-users-pestid-footer-button back"
                                                children={'Cancel'}
                                                onClick={() => setModalVisible(false)}
                                            />
                                            <ButtonComponent
                                                className="modal-users-pestid-footer-button next"
                                                children={userSelected ? 'Save' : 'Add'}
                                                onClick={() => formik.handleSubmit()}
                                                disabled={!formik.dirty || !formik.isValid}
                                            />
                                        </>
                                    ) : (
                                        <ButtonComponent children="Ok" onClick={() => handleClose(formik)} />
                                    )}
                                </div>
                            }
                            header={
                                <div className="modal-users-pestid-header-content">
                                    {numPage === 1 ? <h2>{userSelected ? 'Update User' : 'New User'}</h2> : null}
                                    <div className="modal-users-pestid-header-button-close" onClick={() => handleClose(formik)}>
                                        X
                                    </div>
                                </div>
                            }
                            children={
                                <div ref={formRef} className="modal-users-pestid-body-content">
                                    {numPage === 1 ? (
                                        <>
                                            <h3>User name</h3>
                                            <InputComponent
                                                formik={formik}
                                                id="name"
                                                name="name"
                                                type="textarea"
                                                placeholder={formik.values.name}
                                            />
                                            <h3>User mail</h3>
                                            <InputComponent
                                                formik={formik}
                                                id="mail"
                                                name="mail"
                                                type="textarea"
                                                placeholder={formik.values.mail}
                                            />
                                            <div className="modal-users-pestid-body-content-selector" onClick={onClickSelectorRole}>
                                                <h3>{formik.values.role || 'Select role'}</h3>
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </div>
                                            {selectorRoleOpen ? (
                                                <div className="modal-users-pestid-body-content-selector-roles-container">
                                                    {userRole.map((role, index) => (
                                                        <div
                                                            key={index.toString()}
                                                            onClick={() => onClickRowRole(role, formik)}
                                                            className="modal-users-pestid-body-content-selector-roles-row"
                                                        >
                                                            <p>{role}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null}
                                            {!companyId && role !== 'cmsmanager' ? (
                                                <>
                                                    <div
                                                        className="modal-users-pestid-body-content-selector"
                                                        onClick={onClickSelectorCompany}
                                                    >
                                                        <h3>{formik.values.company.name || 'Select company'}</h3>
                                                        <FontAwesomeIcon icon={faChevronDown} />
                                                    </div>
                                                    {selectorCompaniesOpen ? (
                                                        <div className="modal-users-pestid-body-content-selector-companies-container">
                                                            <InputComponent
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                }}
                                                                id="search"
                                                                name="search"
                                                                type="text"
                                                                onChange={(event) => setSearchTerm(event.target.value)}
                                                                placeholder="Search"
                                                            />
                                                            {companies
                                                                .filter((company) =>
                                                                    company.name.toLowerCase().includes(searchTerm.toLowerCase())
                                                                )
                                                                .map((company, index) => (
                                                                    <div
                                                                        key={index.toString()}
                                                                        onClick={() => onClickRowCompany(company, formik)}
                                                                        className="modal-users-pestid-body-content-selector-companies-row"
                                                                    >
                                                                        <p>{company.name}</p>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    ) : null}
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}
                                    {numPage === 2 ? (
                                        <div className="modal-users-pestid-body-content-success">
                                            {loading ? (
                                                <Loading />
                                            ) : (
                                                <>
                                                    <CheckCircleSVG />
                                                    {userSelected ? <h2>User updated</h2> : <h2>User created</h2>}
                                                </>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            }
                        />
                    </Form>
                )}
            </Formik>
            <ModalLayout
                maxWidth={'783px'}
                isOpen={showAlert}
                toggleModal={handleCloseAlert}
                footer={
                    <div className="modal-users-pestid-footer-content">
                        <ButtonComponent children="Ok" onClick={handleCloseAlert} />
                    </div>
                }
                children={
                    <div ref={formRef} className="modal-users-pestid-body-content">
                        <Alert className="map-alert" onClose={handleCloseAlert} severity="error">
                            {message}
                        </Alert>
                    </div>
                }
            />
        </>
    );
};

export default UsersPestIDForm;
